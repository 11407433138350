import BigNumber from "bignumber.js"
import Button from "../../components/Button"
import Table from "../../components/Table"
import { useState } from "react"
import { OptionType } from "../hooks/gqldocs"
import { useOptions } from "../hooks/useAdmin"
import { checkValidAddress } from "../utils"
import { useNetwork, useTerraAssets } from "../../hooks"
import { times, div, gt } from "../../libs/math"

const Mixing = () => {
  const getSymbol = useSymbol()
  const [asset, setAsset] = useState("")
  const [amount, setAmount] = useState(0)
  const [address, setAddress] = useState("")
  const [memo, setMemo] = useState("")
  const { options, setOption } = useOptions<
    AdminOption<AdminOptionDetailMixing>,
    { setMixingOptions: AdminOptionDetailMixing }
  >(OptionType.MIXING)

  const checkValidAsset = (asset: string) => {
    if (!asset) return false
    return getSymbol(asset) !== asset
  }

  const checkValidAmount = (amount: string) => {
    return (
      amount && new BigNumber(amount).toFormat(6) !== "NaN" && gt(amount, 0)
    )
  }

  const isValid = () => {
    if (!checkValidAsset(asset)) return false
    if (!checkValidAmount(amount.toString())) return false
    if (!checkValidAddress(address)) return false

    return true
  }

  const send = async () => {
    try {
      const answer = window.confirm(
        `Send ${amount} ${getSymbol(asset)} to ${address}? memo: ${memo}`
      )
      if (answer) {
        const res = await setOption({
          variables: {
            asset,
            amount: times(amount, Math.pow(10, 6)),
            to: address,
            ...(memo && { memo }),
          },
        })
        alert(res?.data?.setMixingOptions)
      }
    } catch (err) {
      alert(`Error: ${err}`)
    }
  }

  return (
    <div>
      <input
        placeholder="Asset"
        onChange={(e) => setAsset(e.target.value)}
      ></input>
      <input
        placeholder="Amount"
        type="number"
        onChange={(e) => setAmount(Number(e.target.value))}
      ></input>
      <input
        placeholder="Address"
        onChange={(e) => setAddress(e.target.value)}
      ></input>
      <input
        placeholder="Memo"
        onChange={(e) => setMemo(e.target.value)}
      ></input>
      {isValid() && (
        <Button
          onClick={(e: Event) => {
            e.preventDefault()
            send()
          }}
        >
          Send
        </Button>
      )}
      {options.length > 0 && (
        <Table
          columns={[
            {
              key: "walletId",
              title: "Wallet Id",
              align: "center",
            },
            {
              key: "asset",
              title: "Symbol",
              render: (value) => getSymbol(value),
              align: "center",
            },
            {
              key: "amount",
              title: "Amount",
              render: (value) => div(value, Math.pow(10, 6)),
              align: "center",
            },
            {
              key: "to",
              title: "To",
              align: "center",
            },
            {
              key: "memo",
              title: "Memo",
              align: "center",
            },
          ]}
          dataSource={options
            .map((item) => item.option)
            .sort((prev, curr) => (prev.id > curr.id ? 1 : -1))}
        />
      )}
    </div>
  )
}

export default Mixing

/* hooks */
export const useSymbol = () => {
  const { name } = useNetwork()
  const { data: tokens } = useTerraAssets("cw20/tokens.json")

  return (asset: string) => {
    if (asset === "uluna") return "LUNA"
    else if (["uusd", "ukrw", "usdr", "umnt"].includes(asset)) {
      return `${asset.slice(1, 3).toUpperCase()}T`
    }
    const symbol = tokens?.[name][asset]?.symbol
    return symbol ?? asset
  }
}
