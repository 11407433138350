import { useState } from "react"
import { range } from "lodash"
import Table from "../../components/Table"
import StatusButton from "../components/StatusButton"
import { gte } from "../../libs/math"
import { formatAsset } from "../../libs/parse"
import { useLiquidateWallets, useOpenOrders } from "../hooks/useAdmin"
import { UUSD } from "../../constants"

const LiquidateOrdersTable = (
  symbol: string,
  id: string,
  wallets: LiquidateWallet[]
) => {
  const address = wallets.find((wallet) => wallet.id === id)?.address ?? ""
  const { orders } = useOpenOrders(`LIQUIDATE_${symbol}`, address)

  return (
    <Table
      columns={[
        {
          key: "address",
          title: "Address",
          align: "center",
        },
        {
          key: "idx",
          title: "Order Idx",
          align: "center",
        },
        {
          key: "premiumSlot",
          title: "Premium Slot",
          align: "center",
        },
        {
          key: "ustAmount",
          title: "Bid Amount",
          render: (value) => (gte(value, 0) ? formatAsset(value, UUSD) : "-"),
          align: "center",
        },
        {
          key: "liquidatedAmount",
          title: "Collateral Amount",
          render: (value) => (gte(value, 0) ? formatAsset(value, symbol) : "-"),
          align: "center",
        },
        {
          key: "waitEnd",
          title: "Status",
          render: (waitEnd) => {
            const onClick = () => {}
            return <StatusButton on={waitEnd === null} onClick={onClick} />
          },
          align: "center",
        },
      ]}
      dataSource={orders}
    />
  )
}

const LiquidateOrders = (symbol: string) => {
  const { wallets } = useLiquidateWallets()
  const [id, setId] = useState("1")

  return (
    <div>
      <select onChange={(e) => setId(e.target.value)}>
        {range(1, 11).map((id) => (
          <option value={id.toString()}> Wallet {id} </option>
        ))}
      </select>
      {LiquidateOrdersTable(symbol, id, wallets)}
    </div>
  )
}

export const LiquidateBlunaOrders = () => LiquidateOrders("BLUNA")
export const LiquidateBethOrders = () => LiquidateOrders("BETH")
