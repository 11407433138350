import { useState } from "react"
import BigNumber from "bignumber.js"
import Table from "../../components/Table"
import Button from "../../components/Button"
import { times, gt } from "../../libs/math"
import { checkValidAddress } from "../utils"
import useGetSymbol from "../hooks/useGetSymbol"
import { useSendToken } from "../hooks/useAdmin"

const SendToken = () => {
  const getSymbol = useGetSymbol()
  const [csvFile, setCsvFile] = useState<File>()
  const [csvArray, setCsvArray] = useState<SendTokenInfo[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const { sendToken } = useSendToken()

  const processCsv = (str: string) => {
    const headers = str
      .slice(0, str.indexOf("\n"))
      .split(",")
      .map((header) => header.trim())
    const rows = str.slice(str.indexOf("\n") + 1).split("\n")

    const processed = rows
      .map((row) => {
        const values = row.split(",").map((value) => value.trim())
        return headers.reduce((obj: any, header, i) => {
          obj[header] = values[i]
          return obj
        }, {})
      })
      .filter((row) => {
        return row.token || row.address || row.amount
      })

    setCsvArray(processed)
  }

  const load = (file?: File) => {
    const reader = new FileReader()

    reader.onload = (e) => {
      const text = e.target?.result
      typeof text === "string" && processCsv(text)
    }

    file && reader.readAsText(file)
  }

  const checkValidToken = (token: string) => {
    if (!token) return false
    return getSymbol(token).toLowerCase() !== token
  }

  const checkValidAmount = (amount: string) => {
    return (
      amount && new BigNumber(amount).toFormat(6) !== "NaN" && gt(amount, 0)
    )
  }

  const isValid = () => {
    if (csvArray.length === 0) return false

    for (const info of csvArray) {
      if (!checkValidToken(info.token ?? "")) return false
      if (!checkValidAddress(info.address ?? "")) return false
      if (!checkValidAmount(info.amount ?? "")) return false
    }

    return true
  }

  const send = async () => {
    setIsLoading(true)

    try {
      const answer = window.confirm("Send?")
      if (answer) {
        const inputs = csvArray.map((info) => {
          return {
            ...info,
            amount: times(info.amount, 1000000),
          }
        })
        const res = await sendToken({ variables: { inputs } })
        if (!res?.data?.sendToken)
          throw new Error(`No txhash available: ${res}`)
        alert(`Check txhash: ${res?.data?.sendToken}`)
      }
    } catch (err) {
      alert(`Error: ${err}`)
    }

    setIsLoading(false)
  }

  return (
    <div>
      <input
        type="file"
        accept=".csv"
        onChange={(e) => setCsvFile(e.target.files?.[0])}
      ></input>
      <Button
        onClick={(e: Event) => {
          e.preventDefault()
          load(csvFile)
        }}
      >
        Load CSV
      </Button>
      {isValid() && (
        <Button
          onClick={(e: Event) => {
            e.preventDefault()
            send()
          }}
        >
          Send Token
        </Button>
      )}
      <br />
      {isLoading && <p>Waiting for TX confirmation...</p>}
      <br />
      {csvArray.length > 0 && (
        <Table
          columns={[
            {
              key: "token",
              title: "Token",
              render: (value) => {
                const isValid = checkValidToken(value ?? "")
                const symbol = isValid ? getSymbol(value) : value
                return (
                  <span className={isValid ? "white" : "red"}>{symbol}</span>
                )
              },
              align: "center",
            },
            {
              key: "address",
              title: "Address",
              render: (value) => (
                <span
                  className={checkValidAddress(value ?? "") ? "white" : "red"}
                >
                  {value}
                </span>
              ),
              align: "center",
            },
            {
              key: "amount",
              title: "Amount",
              render: (value) => {
                const isValid = checkValidAmount(value ?? "")
                const formatted = isValid
                  ? new BigNumber(value).toFormat(6)
                  : value
                return (
                  <span className={isValid ? "white" : "red"}>{formatted}</span>
                )
              },
              align: "center",
            },
          ]}
          dataSource={csvArray}
        />
      )}
    </div>
  )
}

export default SendToken
