import Button from "../../components/Button"

interface Props {
  on: boolean
  onClick: () => void
}

export const getStatusName = (status: boolean) => (status ? "On" : "Off")

const StatusButton = ({ on, onClick }: Props) => (
  <Button onClick={onClick} color={on ? "aqua" : "red"} size="xs" outline>
    {getStatusName(on)}
  </Button>
)

export default StatusButton
