import { ethers } from "ethers"
import abi from "./abi.json"
import BigNumber from "bignumber.js"

type PairInfoData = [
  string, // pair lpToken
  ethers.BigNumber, // pair lpSupply
  string, // ust
  ethers.BigNumber, // ustReserve
  string, // asset
  string, // assetSymbol
  ethers.BigNumber // assetReserve
]

const HELPER_CONTRACT = "0xF497145AD68ed6aDFA981c21e5bCdE949d0C3935"
const INFURA_ID = "a2efa9feabf84deb8f4dc696adf8f360"

const provider = new ethers.providers.InfuraProvider("homestead", INFURA_ID)
const contract = new ethers.Contract(HELPER_CONTRACT, abi, provider)

const queryTokenPrice = async (token: string) => {
  const contracts = [token]
  const [pairInfo]: PairInfoData[] = await contract.fetchPairInfo(contracts)
  const [, , , ustReserve, , , assetReserve] = pairInfo
  const ust = ustReserve.toString()
  const asset = assetReserve.toString()
  const price = new BigNumber(ust).div(asset)
  return price.toString()
}

export default queryTokenPrice
