import { useEffect, useMemo, useState } from "react"
import { gql, useQuery } from "@apollo/client"
import StatusButton, { getStatusName } from "../components/StatusButton"
import Table from "../../components/Table"
import Clickable from "../components/Clickable"
import { gt, gte } from "../../libs/math"
import { format, formatAsset, lookup, toAmount } from "../../libs/parse"
import { OptionType } from "../hooks/gqldocs"
import { useAdminClient, useOptions } from "../hooks/useAdmin"
import useQueryTokenPrice from "../hooks/useQueryTokenPrice"
import { UST, UUSD } from "../../constants"

const DefaultOption: AdminOptionDetailBuyToken = {
  amount: "0",
  maxAmount: "0",
  maxPrice: "0",
  on: false,
  lockMin: 0,
}

const BuyTokenOptions = () => {
  const { options, setOption } = useOptions<
    AdminOption<AdminOptionDetailBuyToken>,
    { setBuyTokenOption: AdminOptionDetailBuyToken }
  >(OptionType.BUY_TOKEN)

  const tokens = useMemo(() => options.map(({ token }) => token), [options])
  const prices = usePrices(tokens)

  /* event */
  interface Params {
    token: string
    symbol: string
  }

  const initOption = ({ token, symbol }: Params) => {
    const answer = window.confirm(`Init ${symbol}`)
    answer && setOption({ variables: { ...DefaultOption, token } })
  }

  interface MutateParams extends Params {
    current: AdminOptionDetailBuyToken
  }

  interface MutateAmountParams extends MutateParams {
    key: "amount" | "maxAmount"
  }

  const mutateAmount = (params: MutateAmountParams) => {
    const { token, symbol, key, current } = params
    const input = prompt(`${symbol}:`, lookup(current[key], symbol)) ?? ""
    const invalid = !gt(input, 0)
      ? "Target must be a number greater than zero"
      : ""

    if (input) {
      invalid
        ? alert(invalid)
        : setOption({
            variables: {
              ...DefaultOption,
              ...current,
              token,
              [key]: toAmount(input),
            },
          })
    }
  }

  interface MutateValueParams extends MutateParams {
    key: "maxPrice"
  }

  const mutateValue = (params: MutateValueParams) => {
    const { token, symbol, key, current } = params
    const input = prompt(`${symbol}:`, current[key]) ?? ""
    const invalid = !gt(input, 0)
      ? "Target must be a number greater than zero"
      : ""

    if (input) {
      invalid
        ? alert(invalid)
        : setOption({
            variables: { ...DefaultOption, ...current, token, [key]: input },
          })
    }
  }

  interface MutateNumberParams extends MutateParams {
    key: "lockMin"
  }

  const mutateNumber = (params: MutateNumberParams) => {
    const { token, symbol, key, current } = params
    const input = prompt(`${symbol}:`, String(current[key])) ?? ""
    const invalid = !gt(input, 0)
      ? "Target must be a number greater than zero"
      : ""

    if (input) {
      invalid
        ? alert(invalid)
        : setOption({
            variables: {
              ...DefaultOption,
              ...current,
              token,
              [key]: Number(input),
            },
          })
    }
  }

  interface MutateStatusParams extends MutateParams {
    key: "on"
  }

  const mutateStatus = (params: MutateStatusParams) => {
    const { token, symbol, key, current } = params
    const next = !current[key]
    const answer = window.confirm(`${getStatusName(next)} ${symbol}?`)

    answer &&
      setOption({
        variables: { ...DefaultOption, ...current, token, [key]: next },
      })
  }

  return (
    <Table
      columns={[
        {
          key: "symbol",
          title: "Ticker",
          bold: true,
        },
        {
          key: "price",
          title: "Price",
          render: (value) => `${format(value)} ${UST}`,
          align: "right",
        },
        {
          key: "option",
          title: "Editable options",
          children: [
            {
              key: "amount",
              title: "Amount",
              render: (value, { token, symbol, option: current }) => {
                const key = "amount"
                const handleClick = () =>
                  !current
                    ? initOption({ token, symbol })
                    : mutateAmount({ token, symbol, current, key })

                return (
                  <Clickable onClick={handleClick}>
                    {gte(value, 0) ? formatAsset(value, UUSD) : "-"}
                  </Clickable>
                )
              },
              align: "center",
            },
            {
              key: "maxPrice",
              title: "Max Price",
              render: (value, { token, symbol, option: current }) => {
                const key = "maxPrice"
                const handleClick = () =>
                  !current
                    ? initOption({ token, symbol })
                    : mutateValue({ token, symbol, current, key })

                return (
                  <Clickable onClick={handleClick}>
                    {gte(value, 0) ? `${value} ${UST}` : "-"}
                  </Clickable>
                )
              },
              align: "center",
            },
            {
              key: "maxAmount",
              title: "Max Amount",
              render: (value, { token, symbol, option: current }) => {
                const key = "maxAmount"
                const handleClick = () =>
                  !current
                    ? initOption({ token, symbol })
                    : mutateAmount({ token, symbol, current, key })

                return (
                  <Clickable onClick={handleClick}>
                    {gte(value, 0) ? formatAsset(value, symbol) : "-"}
                  </Clickable>
                )
              },
              align: "center",
            },
            {
              key: "lockMin",
              title: "Lock Min",
              render: (value, { token, symbol, option: current }) => {
                const key = "lockMin"
                const handleClick = () =>
                  !current
                    ? initOption({ token, symbol })
                    : mutateNumber({ token, symbol, current, key })

                return (
                  <Clickable onClick={handleClick}>
                    {gte(value, 0) ? value : "-"}
                  </Clickable>
                )
              },
              align: "center",
            },
            {
              key: "on",
              title: "On/Off",
              render: (on, { token, symbol, option: current }) => {
                const key = "on"
                const handleClick = () =>
                  !current
                    ? initOption({ token, symbol })
                    : mutateStatus({ token, symbol, current, key })

                return <StatusButton on={on} onClick={handleClick} />
              },
              align: "center",
            },
          ],
        },
      ]}
      dataSource={options
        .map((item) => {
          const { token } = item
          const pair = "0"
          const option = options?.find(
            (option) => option.token === item.token
          )?.option

          return { ...item, ...prices[token], option, pair }
        })
        .sort(({ symbol: a = "" }, { symbol: b = "" }) => a.localeCompare(b))}
    />
  )
}

export default BuyTokenOptions

/* hooks */
const BUY_ASSETS = gql`
  query buyAssets {
    buyAssets {
      symbol
      name
      token
      pair
      lpToken
    }
  }
`

interface BuyAsset {
  name: string
  pair: string
  symbol: string
  token: string
}

interface Item {
  price: string
  symbol: string
}

const usePrices = (tokens: string[]) => {
  const client = useAdminClient()
  const { data } = useQuery<{ buyAssets: BuyAsset[] }>(BUY_ASSETS, { client })
  const queryTokenPrice = useQueryTokenPrice()
  const [prices, setPrices] = useState<Dictionary<Item>>({})

  useEffect(() => {
    const fn = async (buyAssets: BuyAsset[]) => {
      const queries = tokens.map((tokenAddress) =>
        queryTokenPrice(
          buyAssets.find(({ token }) => token === tokenAddress)!.pair
        )
      )

      const responses = await Promise.all(queries)
      const prices = tokens.reduce<Dictionary<Item>>(
        (acc, tokenAddress, index) => ({
          ...acc,
          [tokenAddress]: {
            price: responses[index],
            symbol: buyAssets.find(({ token }) => token === tokenAddress)!
              .symbol,
          },
        }),
        {}
      )

      setPrices(prices)
    }

    data && fn(data.buyAssets)
  }, [data, tokens, queryTokenPrice])

  return prices
}
