import networks from "../networks"
import { useNetwork } from "../hooks"
import Icon from "../components/Icon"
import styles from "./SelectNetwork.module.scss"

const SelectNetwork = () => {
  const { name, setNetwork } = useNetwork()

  return (
    <div className={styles.wrapper}>
      <Icon name="wifi_tethering" className={styles.icon} size={14} />

      <select
        className={styles.select}
        value={name}
        onChange={(e) => setNetwork(e.target.value)}
      >
        {Object.keys(networks).map((key) => (
          <option value={key} key={key}>
            {key}
          </option>
        ))}
      </select>

      <Icon name="arrow_drop_down" className={styles.caret} size={18} />
    </div>
  )
}

export default SelectNetwork
