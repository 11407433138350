import { useState } from "react"
import Table from "../../components/Table"
import Clickable from "../components/Clickable"
import StatusButton, { getStatusName } from "../components/StatusButton"
import Button from "../../components/Button"
import { gt, gte, lte, times, isInteger } from "../../libs/math"
import { formatAsset, lookup, toAmount } from "../../libs/parse"
import { OptionType } from "../hooks/gqldocs"
import { useOptions } from "../hooks/useAdmin"
import { UUSD } from "../../constants"

const DefaultOption: AdminOptionDetailLiquidate = {
  amount: "0",
  slot: 0,
  on: false,
}

const LiquidateOptions = (symbol: string) => {
  const optionType =
    symbol === "BLUNA" ? OptionType.LIQUIDATE_BLUNA : OptionType.LIQUIDATE_BETH
  const { options, setOption, removeOption } = useOptions<
    AdminOption<AdminOptionDetailLiquidate>,
    { setLiquidateOption: AdminOptionDetailLiquidate }
  >(optionType)
  const [addNew, setAddNew] = useState(false)
  const [newSlot, setNewSlot] = useState(0)
  const [newAmount, setNewAmount] = useState(0)

  const initOption = () => {
    const answer = window.confirm(`Init ${symbol}`)
    answer && setOption({ variables: { ...DefaultOption } })
  }

  const remove = (id: string, current: AdminOptionDetailLiquidate) => {
    const answer = window.confirm(`Delete slot ${current.slot}?`)
    answer && removeOption({ variables: { type: optionType, id } })
  }

  interface MutateParams {
    current: AdminOptionDetailLiquidate
    id: string
  }

  interface MutateAmountParams extends MutateParams {
    key: "amount"
  }

  const mutateAmount = (params: MutateAmountParams) => {
    const { key, current, id } = params
    const input = prompt(`${symbol}:`, lookup(current[key], symbol)) ?? ""
    const invalid = !gt(input, 0)
      ? "Target must be a number greater than zero"
      : ""

    if (input) {
      invalid
        ? alert(invalid)
        : setOption({
            variables: {
              ...DefaultOption,
              ...current,
              id,
              [key]: toAmount(input),
            },
          })
    }
  }

  interface MutateStatusParams extends MutateParams {
    key: "on"
  }

  const mutateStatus = (params: MutateStatusParams) => {
    const { key, current, id } = params
    const next = !current[key]
    const answer = window.confirm(
      `${getStatusName(next)} slot ${current.slot}?`
    )

    answer &&
      setOption({
        variables: { ...DefaultOption, ...current, id, [key]: next },
      })
  }

  const isValidNew = () => {
    const existingSlots = options.map((option) => option.option.slot)
    const isValidSlot =
      !existingSlots.includes(newSlot) &&
      isInteger(newSlot) &&
      gte(newSlot, 0) &&
      lte(newSlot, 30)
    const isValidAmount = gt(newAmount, 0)
    return isValidSlot && isValidAmount
  }

  const save = () => {
    const answer = window.confirm(
      `Add new option: slot ${newSlot}, amount ${newAmount}?`
    )
    if (answer) {
      setOption({
        variables: {
          slot: newSlot,
          amount: times(newAmount, 1000000),
          on: false,
        },
      })
      setAddNew(false)
      setNewSlot(0)
      setNewAmount(0)
    }
  }

  return (
    <div>
      <Button
        onClick={(e: Event) => {
          e.preventDefault()
          setAddNew(true)
        }}
      >
        Add New
      </Button>
      <br />
      <br />
      {addNew && (
        <input
          placeholder="Premium slot"
          type="number"
          step="1"
          onChange={(e) => setNewSlot(Number(e.target.value))}
        ></input>
      )}
      {addNew && (
        <input
          placeholder="UST amount"
          type="number"
          onChange={(e) => setNewAmount(Number(e.target.value))}
        ></input>
      )}
      {isValidNew() && (
        <Button
          onClick={(e: Event) => {
            e.preventDefault()
            save()
          }}
        >
          Save
        </Button>
      )}
      <Table
        columns={[
          {
            key: "symbol",
            title: "Symbol",
          },
          {
            key: "slot",
            title: "Premium slot",
          },
          {
            key: "option",
            title: "Editable options",
            children: [
              {
                key: "amount",
                title: "Amount",
                render: (value, { option: current, id }) => {
                  const key = "amount"
                  const handleClick = () =>
                    !current ? initOption() : mutateAmount({ current, key, id })

                  return (
                    <Clickable onClick={handleClick}>
                      {gte(value, 0) ? formatAsset(value, UUSD) : "-"}
                    </Clickable>
                  )
                },
                align: "center",
              },
              {
                key: "on",
                title: "On/Off",
                render: (on, { option: current, id }) => {
                  const key = "on"
                  const handleClick = () =>
                    !current ? initOption() : mutateStatus({ current, key, id })

                  return <StatusButton on={on} onClick={handleClick} />
                },
                align: "center",
              },
            ],
          },
          {
            key: "delete",
            title: "",
            render: (value, { option: current, id }) => {
              const handleClick = () => remove(id, current)
              return <Clickable onClick={handleClick}>Delete</Clickable>
            },
            align: "center",
          },
        ]}
        dataSource={options
          .map((item) => {
            const { option } = item
            const { slot } = option

            return { ...item, option, slot, symbol }
          })
          .sort((prev, curr) => (prev.slot > curr.slot ? 1 : -1))}
      />
    </div>
  )
}

export const LiquidateBlunaOptions = () => LiquidateOptions("BLUNA")
export const LiquidateBethOptions = () => LiquidateOptions("BETH")
