import { FC, HTMLAttributes } from "react"
import styles from "./Clickable.module.scss"

type Props = HTMLAttributes<HTMLButtonElement>
const Clickable: FC<Props> = ({ children, onClick }) => (
  <button className={styles.component} onClick={onClick}>
    {children}
  </button>
)

export default Clickable
