import Tab from "../../components/Tab"
import useHash from "../../libs/useHash"
import UniswapMMOptions from "./UniswapMMOptions"
import UniswapMMHistory from "./UniswapMMHistory"

enum Type {
  OPTIONS = "Options",
  HISTORY = "History",
}

const UniswapMM = () => {
  const { hash: type } = useHash<Type>(Type.OPTIONS)

  return (
    <Tab tabs={[Type.OPTIONS, Type.HISTORY]} current={type}>
      {
        {
          [Type.OPTIONS]: <UniswapMMOptions />,
          [Type.HISTORY]: <UniswapMMHistory />,
        }[type]
      }
    </Tab>
  )
}

export default UniswapMM
