import axios from "axios"
import BigNumber from "bignumber.js"
import { useCallback } from "react"
import { useNetwork } from "../../hooks"

type Info =
  | { token: { contractAddress: string } }
  | { native_token: { denom: "uusd" } }

interface PoolAsset {
  amount: string
  info: Info
}

type PoolAssets = [PoolAsset, PoolAsset]

interface Data {
  height: string
  result: {
    assets: PoolAssets
    total_share: string
  }
}

const useQueryTokenPrice = () => {
  const { lcd: baseURL } = useNetwork()

  const queryTokenPrice = useCallback(
    async (pairAddress: string) => {
      try {
        const path = `/wasm/contracts/${pairAddress}/store`
        const params = { query_msg: JSON.stringify({ pool: {} }) }
        const { data } = await axios.get<Data>(path, { baseURL, params })
        const { uusd, asset } = parsePool(data.result.assets)
        const price = new BigNumber(uusd).div(asset)
        return price.toString()
      } catch {
        return "0"
      }
    },
    [baseURL]
  )

  return queryTokenPrice
}

export default useQueryTokenPrice

/* helpers */
const parsePool = (assets: PoolAssets) =>
  assets.reduce<{ uusd: string; asset: string }>(
    (acc, { amount, info }) => {
      const key = "native_token" in info ? "uusd" : "asset"
      return { ...acc, [key]: amount }
    },
    { uusd: "0", asset: "0" }
  )
