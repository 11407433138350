import { useEffect, useMemo, useState } from "react"
import Table from "../../components/Table"
import Clickable from "../components/Clickable"
import StatusButton, { getStatusName } from "../components/StatusButton"
import WithResult from "../../containers/WithResult"
import { div, gt, lt, minus } from "../../libs/math"
import { format, lookupSymbol } from "../../libs/parse"
import { percent } from "../../libs/num"
import { parsePercent } from "../utils"
import { useNetwork } from "../../hooks"
import { OptionType } from "../hooks/gqldocs"
import { useOptions } from "../hooks/useAdmin"
import useLCDClient from "../hooks/useLCDClient"
import queryTokenPrice from "../ethereum/querySymbolPrice"
import { MIR } from "../../constants"
import columbus from "../whitelist/columbus/uniswap_pairs.json"
import bombay from "../whitelist/bombay/uniswap_pairs.json"

interface Pair {
  assets: string[]
  tokens: string[]
  pair: string
}

const whitelist: Dictionary<Dictionary<Pair>> = {
  classic: columbus,
  testnet: bombay,
}

enum Position {
  BID = "bid",
  ASK = "ask",
}

const DefaultOption: AdminOptionDetailUniswap = {
  askOn: false,
  bidOn: false,
  askTolerance: "0.02",
  bidTolerance: "0.02",
}

const UniswapMMOptions = () => {
  const pairs = usePairs()
  const exchangeRates = useLunaExchangeRates()
  const uniswapPrices = useUniswapPrices(pairs)

  const { options, setOption } = useOptions<
    AdminOption<AdminOptionDetailUniswap>,
    { setMarketMakingOption: AdminOptionDetailUniswap }
  >(OptionType.UNISWAP_MM)

  /* event */
  interface Params {
    pair: string
    symbol: string
  }

  const initOption = ({ pair, symbol }: Params) => {
    const answer = window.confirm(`Init ${symbol}`)
    answer && setOption({ variables: { ...DefaultOption, pair } })
  }

  interface MutateParams extends Params {
    current: AdminOptionDetailUniswap
  }

  interface MutatePercentParams extends MutateParams {
    key: "bidTolerance" | "askTolerance"
  }

  const mutatePercent = (params: MutatePercentParams) => {
    const { pair, symbol, key, current } = params
    const input = prompt(`Target ${symbol}:`, percent(current[key])) ?? ""

    const parsed = parsePercent(input)
    const invalid = !input?.endsWith("%")
      ? "Target must end with '%'"
      : !(gt(parsed, 0) || lt(parsed, 0))
      ? "Target must be a non-zero number"
      : ""

    if (input) {
      invalid
        ? alert(invalid)
        : setOption({
            variables: { ...DefaultOption, ...current, pair, [key]: parsed },
          })
    }
  }

  interface MutateStatusParams extends MutateParams {
    key: "bidOn" | "askOn"
  }

  const mutateStatus = (params: MutateStatusParams) => {
    const { pair, symbol, key, current } = params
    const next = !current[key]
    const answer = window.confirm(`${getStatusName(next)} ${symbol}?`)

    answer &&
      setOption({
        variables: { ...DefaultOption, ...current, pair, [key]: next },
      })
  }

  return (
    <WithResult loadingFirst>
      <Table
        columns={[
          {
            key: "symbol",
            title: "Ticker",
            bold: true,
          },
          {
            key: "oracle",
            title: "Oracle Price",
            render: (value, { denom }) =>
              gt(value, 0) && `${format(value)} ${lookupSymbol(denom)}`,
            align: "right",
          },
          {
            key: "uniswap",
            title: "Uniswap Price",
            render: (value, { denom }) =>
              `${format(value)} ${lookupSymbol(denom)}`,
            align: "right",
          },
          {
            key: "spread",
            render: (value) => <Percent>{value}</Percent>,
            align: "right",
          },
          {
            key: Position.BID,
            children: [
              {
                key: "tolerance",
                title: "Tolerance",
                render: (value, { pair, symbol, option: current }) => {
                  const key = "bidTolerance"
                  const handleClick = () =>
                    !current
                      ? initOption({ pair, symbol })
                      : mutatePercent({ pair, symbol, current, key })

                  return (
                    symbol !== MIR && (
                      <Clickable onClick={handleClick}>
                        {value ? <Percent>{value}</Percent> : "-"}
                      </Clickable>
                    )
                  )
                },
                align: "center",
              },
              {
                key: "on",
                title: "Status",
                render: (on, { pair, symbol, option: current }) => {
                  const key = "bidOn"
                  const handleClick = () =>
                    !current
                      ? initOption({ pair, symbol })
                      : mutateStatus({ pair, symbol, current, key })

                  return (
                    symbol !== MIR && (
                      <StatusButton on={on} onClick={handleClick} />
                    )
                  )
                },
                align: "center",
              },
            ],
          },
          {
            key: Position.ASK,
            children: [
              {
                key: "tolerance",
                title: "Tolerance",
                render: (value, { pair, symbol, option: current }) => {
                  const key = "askTolerance"
                  const handleClick = () =>
                    !current
                      ? initOption({ pair, symbol })
                      : mutatePercent({ pair, symbol, current, key })

                  return (
                    symbol !== MIR && (
                      <Clickable onClick={handleClick}>
                        {value ? <Percent>{value}</Percent> : "-"}
                      </Clickable>
                    )
                  )
                },
                align: "center",
              },
              {
                key: "on",
                title: "Status",
                render: (on, { pair, symbol, option: current }) => {
                  const key = "askOn"
                  const handleClick = () =>
                    !current
                      ? initOption({ pair, symbol })
                      : mutateStatus({ pair, symbol, current, key })

                  return (
                    symbol !== MIR && (
                      <StatusButton on={on} onClick={handleClick} />
                    )
                  )
                },
                align: "center",
              },
            ],
          },
        ]}
        dataSource={Object.values(pairs).map((item) => {
          const { assets } = item

          const option = options?.find(
            (option) => option.token === item.pair
          )?.option

          const denom = getDenomFromAssets(assets)

          const uniswap = denom ? uniswapPrices[denom] : "0"
          const oracle = denom ? exchangeRates[denom] : "0"

          return {
            ...item,
            option,
            denom,
            symbol: assets.map(lookupSymbol).join("/"),
            uniswap,
            oracle,
            spread: calcSpread({ pair: uniswap, oracle }),
            bid: {
              tolerance: option?.bidTolerance,
              on: option?.bidOn,
            },
            ask: {
              tolerance: option?.askTolerance,
              on: option?.askOn,
            },
          }
        })}
      />
    </WithResult>
  )
}

export default UniswapMMOptions

/* helpers */
const calcSpread = ({ pair, oracle }: { pair: string; oracle: string }) =>
  div(minus(pair, oracle), oracle)

const getColor = (value: string) =>
  gt(value, 0) ? "aqua" : lt(value, 0) ? "red" : ""

const Percent = ({ children: value }: { children: string }) => (
  <span className={getColor(value)}>{percent(value)}</span>
)

export const getDenomFromAssets = (assets: string[]) =>
  assets.find((denom) => denom !== "LUNA")

/* hooks */
export const usePairs = () => {
  const { name } = useNetwork()
  const pair = useMemo(() => whitelist[name] ?? {}, [name])
  return pair
}

const useLunaExchangeRates = () => {
  const lcd = useLCDClient()
  const [exchangeRates, setExchangeRates] = useState<Dictionary>({})

  useEffect(() => {
    const fn = async () => {
      const response = await lcd.oracle.exchangeRates()
      const exchangeRates = response.toArray().reduce(
        (acc, coin) => ({
          ...acc,
          [lookupSymbol(coin.denom)]: coin.amount.toString(),
        }),
        {}
      )

      setExchangeRates(exchangeRates)
    }

    fn()
  }, [lcd])

  return exchangeRates
}

const useUniswapPrices = (pairs: Dictionary<Pair>) => {
  const [prices, setPrices] = useState<Dictionary>({})

  const tokens = useMemo(() => {
    const findLunaTokenAddress = ({ assets, tokens }: Pair) => {
      const LunaIndex = assets.findIndex((denom) => denom === "LUNA")
      return tokens[LunaIndex]
    }

    return Object.values(pairs).reduce<Dictionary>(
      (acc, pair) => ({
        ...acc,
        [getDenomFromAssets(pair.assets) ?? ""]: findLunaTokenAddress(pair),
      }),
      {}
    )
  }, [pairs])

  useEffect(() => {
    const tokenList = Object.entries(tokens)
    const queries = tokenList.map(([, tokenAddress]) =>
      queryTokenPrice(tokenAddress)
    )

    const fn = async () => {
      const responses = await Promise.all(queries)
      const prices = tokenList.reduce((acc, [denom], index) => {
        return { ...acc, [denom]: responses[index] }
      }, {})

      setPrices(prices)
    }

    fn()
  }, [tokens])

  return prices
}
