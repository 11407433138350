import Tab from "../../components/Tab"
import useHash from "../../libs/useHash"
import { LiquidateBlunaOptions, LiquidateBethOptions } from "./LiquidateOptions"
import { LiquidateBlunaOrders, LiquidateBethOrders } from "./LiquidateOrders"

enum Type {
  BLUNA_OPTIONS = "BLUNA Options",
  BETH_OPTIONS = "BETH Options",
  BLUNA_ORDERS = "BLUNA Orders",
  BETH_ORDERS = "BETH Orders",
}

const Liquidate = () => {
  const { hash: type } = useHash<Type>(Type.BLUNA_OPTIONS)

  return (
    <Tab
      tabs={[
        Type.BLUNA_OPTIONS,
        Type.BETH_OPTIONS,
        Type.BLUNA_ORDERS,
        Type.BETH_ORDERS,
      ]}
      current={type}
    >
      {
        {
          [Type.BLUNA_OPTIONS]: <LiquidateBlunaOptions />,
          [Type.BETH_OPTIONS]: <LiquidateBethOptions />,
          [Type.BLUNA_ORDERS]: <LiquidateBlunaOrders />,
          [Type.BETH_ORDERS]: <LiquidateBethOrders />,
        }[type]
      }
    </Tab>
  )
}

export default Liquidate
