import { useNetwork } from "../hooks"
import AppHeader from "../components/AppHeader"
import { MenuKey, getPath, omit } from "../admin/routes"
import SelectNetwork from "./SelectNetwork"

const Header = () => {
  const menuKeys = Object.values(MenuKey).filter((key) => !omit.includes(key))
  const menu = menuKeys.map((key: MenuKey) => ({
    attrs: { to: getPath(key), children: key },
  }))

  const { name } = useNetwork()

  return (
    <AppHeader
      menu={menu}
      connect={<SelectNetwork />}
      testnet={name !== "classic"}
    />
  )
}

export default Header
