import axios from "axios"
import { useEffect, useMemo, useState } from "react"
import Table from "../../components/Table"
import Clickable from "../components/Clickable"
import StatusButton, { getStatusName } from "../components/StatusButton"
import WithResult from "../../containers/WithResult"
import { div, gt, lt, minus } from "../../libs/math"
import { format } from "../../libs/parse"
import { percent } from "../../libs/num"
import { parsePercent } from "../utils"
import { useNetwork } from "../../hooks"
import { OptionType } from "../hooks/gqldocs"
import { useOptions } from "../hooks/useAdmin"
import useLCDClient from "../hooks/useLCDClient"
import useGetSymbol from "../hooks/useGetSymbol"
import { MIR } from "../../constants"
import columbus from "../whitelist/columbus/terraswap_pairs.json"
import bombay from "../whitelist/bombay/terraswap_pairs.json"

interface Pair {
  assets: string[]
  pair: string
}

const whitelist: Dictionary<Dictionary<Pair>> = {
  classic: columbus,
  testnet: bombay,
}

enum Position {
  BID = "bid",
  ASK = "ask",
}

const DefaultOption: AdminOptionDetailTerraswap = {
  askOn: false,
  bidOn: false,
  askTolerance: "0.02",
  bidTolerance: "0.02",
  askTargetSpread: "0.02",
  bidTargetSpread: "0.02",
}

const TerraswapMMOptions = () => {
  const pairs = usePairs()
  const exchangeRates = useLunaExchangeRates()
  const bLunaPrice = useBLunaOraclePrice()
  const terraswapPrices = useTerraswapPrices(pairs)
  const getSymbol = useGetSymbol()

  const { options, setOption } = useOptions<
    AdminOption<AdminOptionDetailTerraswap>,
    { setMarketMakingOption: AdminOptionDetailTerraswap }
  >(OptionType.TERRASWAP_MM)

  /* event */
  interface Params {
    pair: string
    symbol: string
  }

  const initOption = ({ pair, symbol }: Params) => {
    const answer = window.confirm(`Init ${symbol}`)
    answer && setOption({ variables: { ...DefaultOption, pair } })
  }

  interface MutateParams extends Params {
    current: AdminOptionDetailTerraswap
  }

  interface MutatePercentParams extends MutateParams {
    key: "bidTolerance" | "askTolerance" | "bidTargetSpread" | "askTargetSpread"
  }

  const mutatePercent = (params: MutatePercentParams) => {
    const { pair, symbol, key, current } = params
    const input = prompt(`Target ${symbol}:`, percent(current[key])) ?? ""
    const parsed = parsePercent(input)
    const invalid = !input?.endsWith("%") ? "Target must end with '%'" : ""

    if (input) {
      invalid
        ? alert(invalid)
        : setOption({
            variables: { ...DefaultOption, ...current, pair, [key]: parsed },
          })
    }
  }

  interface MutateStatusParams extends MutateParams {
    key: "bidOn" | "askOn"
  }

  const mutateStatus = (params: MutateStatusParams) => {
    const { pair, symbol, key, current } = params
    const next = !current[key]
    const answer = window.confirm(`${getStatusName(next)} ${symbol}?`)

    answer &&
      setOption({
        variables: { ...DefaultOption, ...current, pair, [key]: next },
      })
  }

  return (
    <WithResult loadingFirst>
      <Table
        columns={[
          {
            key: "symbol",
            title: "Ticker",
            bold: true,
          },
          {
            key: "oracle",
            title: "Oracle Price",
            render: (value, { assets: [, asset] }) =>
              gt(value, 0) && `${format(value)} ${getSymbol(asset)}`,
            align: "right",
          },
          {
            key: "terraswap",
            title: "Terraswap Price",
            render: (value, { assets: [, asset] }) =>
              `${format(value)} ${getSymbol(asset)}`,
            align: "right",
          },
          {
            key: "spread",
            render: (value) => <Percent>{value}</Percent>,
            align: "right",
          },
          {
            key: Position.BID,
            children: [
              {
                key: "tolerance",
                title: "Tolerance",
                render: (value, { pair, symbol, option: current }) => {
                  const key = "bidTolerance"
                  const handleClick = () =>
                    !current
                      ? initOption({ pair, symbol })
                      : mutatePercent({ pair, symbol, current, key })

                  return (
                    symbol !== MIR && (
                      <Clickable onClick={handleClick}>
                        {value ? <Percent>{value}</Percent> : "-"}
                      </Clickable>
                    )
                  )
                },
                align: "center",
              },
              {
                key: "target",
                title: "Target",
                render: (value, { pair, symbol, option: current }) => {
                  const key = "bidTargetSpread"
                  const handleClick = () =>
                    !current
                      ? initOption({ pair, symbol })
                      : mutatePercent({ pair, symbol, current, key })

                  return (
                    symbol !== MIR && (
                      <Clickable onClick={handleClick}>
                        {value ? <Percent>{value}</Percent> : "-"}
                      </Clickable>
                    )
                  )
                },
                align: "center",
              },
              {
                key: "on",
                title: "Status",
                render: (on, { pair, symbol, option: current }) => {
                  const key = "bidOn"
                  const handleClick = () =>
                    !current
                      ? initOption({ pair, symbol })
                      : mutateStatus({ pair, symbol, current, key })

                  return (
                    symbol !== MIR && (
                      <StatusButton on={on} onClick={handleClick} />
                    )
                  )
                },
                align: "center",
              },
            ],
          },
          {
            key: Position.ASK,
            children: [
              {
                key: "tolerance",
                title: "Tolerance",
                render: (value, { pair, symbol, option: current }) => {
                  const key = "askTolerance"
                  const handleClick = () =>
                    !current
                      ? initOption({ pair, symbol })
                      : mutatePercent({ pair, symbol, current, key })

                  return (
                    symbol !== MIR && (
                      <Clickable onClick={handleClick}>
                        {value ? <Percent>{value}</Percent> : "-"}
                      </Clickable>
                    )
                  )
                },
                align: "center",
              },
              {
                key: "target",
                title: "Target",
                render: (value, { pair, symbol, option: current }) => {
                  const key = "askTargetSpread"
                  const handleClick = () =>
                    !current
                      ? initOption({ pair, symbol })
                      : mutatePercent({ pair, symbol, current, key })

                  return (
                    symbol !== MIR && (
                      <Clickable onClick={handleClick}>
                        {value ? <Percent>{value}</Percent> : "-"}
                      </Clickable>
                    )
                  )
                },
                align: "center",
              },
              {
                key: "on",
                title: "Status",
                render: (on, { pair, symbol, option: current }) => {
                  const key = "askOn"
                  const handleClick = () =>
                    !current
                      ? initOption({ pair, symbol })
                      : mutateStatus({ pair, symbol, current, key })

                  return (
                    symbol !== MIR && (
                      <StatusButton on={on} onClick={handleClick} />
                    )
                  )
                },
                align: "center",
              },
            ],
          },
        ]}
        dataSource={Object.values(pairs).map((item) => {
          const { assets } = item

          const option = options?.find(
            (option) => option.token === item.pair
          )?.option

          const asset = getAssetFromLunaPair(assets)

          const terraswap = asset ? terraswapPrices[asset] : "0"
          const oracle =
            getSymbol(asset) === "bLUNA"
              ? bLunaPrice
              : asset
              ? exchangeRates[asset]
              : "0"

          return {
            ...item,
            option,
            asset,
            symbol: assets.map(getSymbol).join("/"),
            terraswap,
            oracle,
            spread: calcSpread({ pair: terraswap, oracle }),
            bid: {
              tolerance: option?.bidTolerance,
              target: option?.bidTargetSpread,
              on: option?.bidOn,
            },
            ask: {
              tolerance: option?.askTolerance,
              target: option?.askTargetSpread,
              on: option?.askOn,
            },
          }
        })}
      />
    </WithResult>
  )
}

export default TerraswapMMOptions

/* helpers */
const calcSpread = ({ pair, oracle }: { pair: string; oracle: string }) =>
  div(minus(pair, oracle), oracle)

const getColor = (value: string) =>
  gt(value, 0) ? "aqua" : lt(value, 0) ? "red" : ""

const Percent = ({ children: value }: { children: string }) => (
  <span className={getColor(value)}>{percent(value)}</span>
)

const getAssetFromLunaPair = (assets: string[]) =>
  assets.find((denom) => denom !== "uluna")!

/* hooks */
export const usePairs = () => {
  const { name } = useNetwork()
  const pair = useMemo(() => whitelist[name] ?? {}, [name])
  return pair
}

const useLunaExchangeRates = () => {
  const lcd = useLCDClient()
  const [exchangeRates, setExchangeRates] = useState<Dictionary>({})

  useEffect(() => {
    const fn = async () => {
      const response = await lcd.oracle.exchangeRates()
      const exchangeRates = response
        .toArray()
        .reduce(
          (acc, coin) => ({ ...acc, [coin.denom]: coin.amount.toString() }),
          {}
        )

      setExchangeRates(exchangeRates)
    }

    fn()
  }, [lcd])

  return exchangeRates
}

const useBLunaOraclePrice = () => {
  const [price, setPrice] = useState("0")

  useEffect(() => {
    const fetch = async () => {
      const { data } = await axios.get<{ exchange_rate: string }>(
        "https://api.anchorprotocol.com/api/bassets/bluna"
      )

      setPrice(data.exchange_rate)
    }

    fetch()
  }, [])

  return price
}

interface PairPool {
  assets: (AssetToken | NativeToken)[]
  total_share: string
}

const useTerraswapPrices = (pairs: Dictionary<Pair>) => {
  const lcd = useLCDClient()
  const [prices, setPrices] = useState<Dictionary>({})
  const keys = useMemo(() => Object.keys(pairs), [pairs])

  useEffect(() => {
    const queries = keys.map((pair) =>
      lcd.wasm.contractQuery<PairPool>(pair, { pool: {} })
    )

    const fn = async () => {
      const responses = await Promise.all(queries)
      const prices = keys.reduce((acc, key, index) => {
        const denom = getAssetFromLunaPair(pairs[key].assets)
        return {
          ...acc,
          [denom]: calcPairPrice(responses[index], pairs[key].assets),
        }
      }, {})

      setPrices(prices)
    }

    fn()
  }, [lcd, keys, pairs])

  return prices
}

export const calcPairPrice = (
  { assets }: PairPool,
  [asset0, asset1]: string[]
) => {
  const getAsset = ({ info }: Token) =>
    "native_token" in info ? info.native_token.denom : info.token.contract_addr

  const amount0 = assets.find((asset) => getAsset(asset) === asset0)?.amount
  const amount1 = assets.find((asset) => getAsset(asset) === asset1)?.amount

  return [amount0, amount1].every((v) => v && gt(v, 0))
    ? div(amount1, amount0)
    : "0"
}
