import { useNetwork, useTerraAssets } from "../../hooks"
import { lookupSymbol } from "../../libs/parse"

const useGetSymbol = () => {
  const { name } = useNetwork()
  const { data: tokens } = useTerraAssets("cw20/tokens.json")
  return (asset: string) => {
    const symbol = tokens?.[name][asset]?.symbol
    return !symbol
      ? lookupSymbol(asset).toUpperCase()
      : symbol === "bLuna"
      ? "bLUNA"
      : symbol
  }
}

export default useGetSymbol
