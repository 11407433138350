import Tab from "../../components/Tab"
import useHash from "../../libs/useHash"
import OnChainSwapOptions from "./OnChainSwapOptions"
import OnChainSwapHistory from "./OnChainSwapHistory"

enum Type {
  OPTIONS = "Options",
  HISTORY = "History",
}

const OnChainSwap = () => {
  const { hash: type } = useHash<Type>(Type.OPTIONS)

  return (
    <Tab tabs={[Type.OPTIONS, Type.HISTORY]} current={type}>
      {
        {
          [Type.OPTIONS]: <OnChainSwapOptions />,
          [Type.HISTORY]: <OnChainSwapHistory />,
        }[type]
      }
    </Tab>
  )
}

export default OnChainSwap
