import { useMemo, useState } from "react"
import { ApolloClient, InMemoryCache } from "@apollo/client"
import { useMutation, useQuery } from "@apollo/client"
import { useNetwork } from "../../hooks"
import { DefaultApolloClientOptions } from "../../layouts/Network"
import { OFFSET } from "../constants"
import { OptionType } from "./gqldocs"
import * as gqldocs from "./gqldocs"

export const useAdminClient = () => {
  const { admin: uri } = useNetwork()
  const client = useMemo(
    () =>
      new ApolloClient({
        uri,
        cache: new InMemoryCache({
          typePolicies: {
            Query: {
              fields: {
                txs: {
                  keyArgs: false,
                  merge(existing = [], incoming) {
                    return [...existing, ...incoming]
                  },
                },
              },
            },
          },
        }),
        connectToDevTools: true,
        defaultOptions: DefaultApolloClientOptions,
      }),
    [uri]
  )

  return client
}

export const useTxs = (type: string) => {
  const client = useAdminClient()
  const result = useQuery<{ txs: AdminTx[] }>(gqldocs.TXS, {
    variables: { type, offset: 0, limit: OFFSET },
    client,
  })

  return { result, history: result.data?.txs ?? [] }
}

export const useOptions = <T, K>(type: OptionType) => {
  const query = {
    [OptionType.TERRASWAP_MM]: gqldocs.OPTIONS_TERRASWAP,
    [OptionType.UNISWAP_MM]: gqldocs.OPTIONS_UNISWAP,
    [OptionType.BUY_TOKEN]: gqldocs.OPTIONS_BUYTOKEN,
    [OptionType.SELL_TOKEN]: gqldocs.OPTIONS_SELLTOKEN,
    [OptionType.ONCHAIN_SWAP]: gqldocs.OPTIONS_SWAP,
    [OptionType.LIQUIDATE_BLUNA]: gqldocs.OPTIONS_LIQUIDATE_BLUNA,
    [OptionType.LIQUIDATE_BETH]: gqldocs.OPTIONS_LIQUIDATE_BETH,
    [OptionType.MIXING]: gqldocs.OPTIONS_MIXING,
  }

  const mutate = {
    [OptionType.TERRASWAP_MM]: gqldocs.SET_OPTION_TERRASWAP,
    [OptionType.UNISWAP_MM]: gqldocs.SET_OPTION_UNISWAP,
    [OptionType.BUY_TOKEN]: gqldocs.SET_OPTION_BUYTOKEN,
    [OptionType.SELL_TOKEN]: gqldocs.SET_OPTION_SELLTOKEN,
    [OptionType.ONCHAIN_SWAP]: gqldocs.SET_OPTION_SWAP,
    [OptionType.LIQUIDATE_BLUNA]: gqldocs.SET_OPTION_LIQUIDATE_BLUNA,
    [OptionType.LIQUIDATE_BETH]: gqldocs.SET_OPTION_LIQUIDATE_BETH,
    [OptionType.MIXING]: gqldocs.SET_OPTIONS_MIXING,
  }

  const [options, setOptions] = useState<T[]>([])

  const client = useAdminClient()

  const result = useQuery<{ options: T[] }>(query[type], {
    client,
    onCompleted: ({ options }) => setOptions(options),
  })

  const [setOption] = useMutation<K>(mutate[type], {
    client,
    onCompleted: () => result.refetch(),
  })

  const [removeOption] = useMutation<K>(gqldocs.REMOVE_OPTION, {
    client,
    onCompleted: () => result.refetch(),
  })

  return { result, options, setOption, removeOption }
}

export const useLiquidateWallets = () => {
  const client = useAdminClient()
  const result = useQuery<{ liquidateWallets: LiquidateWallet[] }>(
    gqldocs.WALLETS,
    {
      client,
    }
  )

  return { result, wallets: result?.data?.liquidateWallets ?? [] }
}

export const useOpenOrders = (type: string, address: string) => {
  const client = useAdminClient()
  const result = useQuery<{ openOrdersByWallet: OpenOrder[] }>(
    gqldocs.OPEN_ORDERS,
    {
      variables: { type, address },
      client,
    }
  )

  return { result, orders: result?.data?.openOrdersByWallet ?? [] }
}

export const useSendToken = () => {
  const client = useAdminClient()
  const [sendToken] = useMutation(gqldocs.SEND_TOKEN, { client })

  return { sendToken }
}
