import { LCDClient } from "@terra-money/terra.js"
import { useMemo } from "react"
import { useNetwork } from "../../hooks"

const useLCDClient = () => {
  const { lcd, chainID } = useNetwork()

  const lcdClient = useMemo(() => {
    const lcdClientConfig = { URL: lcd, chainID, isClassic: true }
    return new LCDClient(lcdClientConfig)
  }, [lcd, chainID])

  return lcdClient
}

export default useLCDClient
