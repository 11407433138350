import { ChangeEvent, FormEvent, useState } from "react"
import routes from "../routes"
import { menu } from "../admin/routes"
import Container from "../components/Container"
import { SettingsProvider, useSettingsState } from "../hooks/useSettings"
import { WalletProvider, useWalletState } from "../hooks/useWallet"
import { ContractProvider, useContractState } from "../hooks/useContract"
import { PASSWORD } from "../constants"
import Header from "./Header"
import "./App.scss"

const App = () => {
  const settings = useSettingsState()
  const wallet = useWalletState()
  const contract = useContractState(wallet.address)

  const [input, setInput] = useState("")
  const [error, setError] = useState("")
  const [authenticated, setAuthenticated] = useState(false)

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value)
    setError("")
  }

  const submit = (e: FormEvent) => {
    e.preventDefault()
    if (input === PASSWORD) setAuthenticated(true)
    else setError("Invalid password.")
  }

  return (
    <>
      {!authenticated ? (
        <form onSubmit={submit}>
          <input type="password" value={input} onChange={handleChange} />
          <button type="submit">Submit</button>
          {error && <p>{error}</p>}
        </form>
      ) : (
        <SettingsProvider value={settings}>
          <WalletProvider value={wallet} key={wallet.address}>
            <ContractProvider value={contract}>
              <Header />
              <Container>{routes(menu)}</Container>
            </ContractProvider>
          </WalletProvider>
        </SettingsProvider>
      )}
    </>
  )
}

export default App
