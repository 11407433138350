import { format as formatDate } from "date-fns"
import Table from "../../components/Table"
import Button from "../../components/Button"
import WithResult from "../../containers/WithResult"
import { useContractsAddress } from "../../hooks"
import { useTxs } from "../hooks/useAdmin"
import { OFFSET } from "../constants"

const BuyTokenHistory = () => {
  const { result, history } = useTxs("BUY_TOKEN")

  const { getSymbol } = useContractsAddress()
  const { fetchMore, loading } = result

  return (
    <WithResult results={[result]}>
      <Table
        columns={[
          {
            key: "datetime",
            title: "Timestamp",
            render: (timestamp) => formatDate(timestamp, "yyyy.MM.dd HH:mm:ss"),
            align: "center",
          },
          {
            key: "token",
            title: "Ticker",
            render: (token) => getSymbol(token),
            bold: true,
          },
          {
            key: "action",
            title: "Action",
            render: (text) => {
              const colors: Dict<string> = { BUY: "aqua", SELL: "red" }
              return <span className={colors[text]}>{text}</span>
            },
            align: "center",
          },
          {
            key: "data",
            title: "Data",
            render: (data) => <pre>{JSON.stringify(data, null, 2)}</pre>,
          },
        ]}
        dataSource={history}
      />

      {history.length >= OFFSET && (
        <Button
          onClick={() =>
            fetchMore({ variables: { offset: history.length, limit: OFFSET } })
          }
          disabled={loading}
          color="secondary"
          outline
          block
        >
          More
        </Button>
      )}
    </WithResult>
  )
}

export default BuyTokenHistory
