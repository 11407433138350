import { format as formatDate } from "date-fns"
import Table from "../../components/Table"
import Button from "../../components/Button"
import WithResult from "../../containers/WithResult"
import { format, lookupSymbol } from "../../libs/parse"
import { percent } from "../../libs/num"
import { useTxs } from "../hooks/useAdmin"
import { OFFSET } from "../constants"
import { getDenomFromAssets, usePairs } from "./UniswapMMOptions"

const UniswapMMHistory = () => {
  const pairs = usePairs()
  const { result, history } = useTxs("UNISWAP_MM")
  const { fetchMore, loading } = result

  const getSymbol = (token: string) =>
    lookupSymbol(getDenomFromAssets(pairs[token].assets))

  return (
    <WithResult results={[result]}>
      <Table
        columns={[
          {
            key: "datetime",
            title: "Timestamp",
            render: (timestamp) => formatDate(timestamp, "yyyy.MM.dd HH:mm:ss"),
            align: "center",
          },
          {
            key: "token",
            title: "Ticker",
            render: (token) => getSymbol(token),
            bold: true,
          },
          {
            key: "action",
            title: "Action",
            render: (text) => {
              const colors: Dict<string> = { BUY: "aqua", SELL: "red" }
              return <span className={colors[text]}>{text}</span>
            },
            align: "center",
          },
          {
            key: "price",
            dataIndex: "data",
            children: [
              {
                key: "price",
                title: "Current",
                render: (value, { token }) =>
                  `${format(value)} ${getSymbol(token)}`,
                align: "right",
              },
              {
                key: "oraclePrice",
                title: "Target",
                render: (value, { token }) =>
                  `${format(value)} ${getSymbol(token)}`,
                align: "right",
              },
            ],
          },
          {
            key: "data.amount",
            title: "Amount",
            render: (value, { data }) => `${format(value)} ${data.offerToken}`,
            align: "right",
          },
          {
            key: "spread",
            dataIndex: "data",
            children: [
              {
                key: "tolerance",
                title: "Tolerance",
                render: (value) => percent(value),
                align: "right",
              },
              {
                key: "targetTolerance",
                title: "Target",
                render: (value) => percent(value),
                align: "right",
              },
            ],
          },
        ]}
        dataSource={history}
      />

      {history.length >= OFFSET && (
        <Button
          onClick={() =>
            fetchMore({ variables: { offset: history.length, limit: OFFSET } })
          }
          disabled={loading}
          color="secondary"
          outline
          block
        >
          More
        </Button>
      )}
    </WithResult>
  )
}

export default UniswapMMHistory
