const networks: Record<string, LocalNetworkConfig> = {
  classic: {
    contract: "https://whitelist.mirror.finance/columbus.json",
    mantle: "https://mantle.terra.dev/",
    admin: "https://adopsv.money/graphql",
    fee: { gasPrice: 0.15, amount: 100000 }, // 0.1 UST
  },
  // testnet: {
  //   contract: "https://whitelist.mirror.finance/bombay.json",
  //   mantle: "https://bombay-mantle.terra.dev/",
  //   admin: "http://localhost:5855/graphql",
  //   fee: { gasPrice: 0.15, amount: 150000 }, // 0.15 UST
  // },
}

export default networks
