import { div } from "../libs/math"
import { bech32 } from "bech32"

export const parsePercent = (value: string) => div(value.replace("%", ""), 100)

const isBech32 = (value: string) => {
  try {
    const words = bech32.decode(value)
    return words.prefix === `terra`
  } catch (error) {
    return false
  }
}

export const checkValidAddress = (address: string) =>
  address &&
  address.length === 44 &&
  address.startsWith("terra") &&
  isBech32(address)
