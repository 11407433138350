import { useEffect } from "react"
import { FINDER } from "../constants"
import { DEFAULT_NETWORK } from "../admin/constants"
import AdminNetworks from "../admin/networks"
import useLocalStorage from "../libs/useLocalStorage"
import networks from "../networks"
import createContext from "./createContext"

const context = createContext<Network>("useNetwork")
export const [useNetwork, NetworkProvider] = context

/* state */
export const useNetworkState = (): Network => {
  const [name, setName] = useLocalStorage("network", DEFAULT_NETWORK)
  const extNetwork = AdminNetworks[name]

  const refresh = async () => {
    // The admin does not import the network settings from the extension.
    // Leave function to modify code less.
  }

  useEffect(() => {
    refresh()
    // eslint-disable-next-line
  }, [])

  const network = networks[extNetwork.name]

  const finder = (address: string, path: string = "account") =>
    `${FINDER}/${extNetwork.chainID}/${path}/${address}`

  return { ...extNetwork, ...network, finder, refresh, setNetwork: setName }
}
