import { format as formatDate } from "date-fns"
import Table from "../../components/Table"
import Button from "../../components/Button"
import WithResult from "../../containers/WithResult"
import { format, formatAsset, lookupSymbol } from "../../libs/parse"
import { useTxs } from "../hooks/useAdmin"
import { OFFSET } from "../constants"
import { UST, UUSD } from "../../constants"

const OnChainSwapHistory = () => {
  const { result, history } = useTxs("ONCHAIN_SWAP")

  const { fetchMore, loading } = result

  return (
    <WithResult results={[result]}>
      <Table
        columns={[
          {
            key: "datetime",
            title: "Timestamp",
            render: (timestamp) => formatDate(timestamp, "yyyy.MM.dd HH:mm:ss"),
            align: "center",
          },
          {
            key: "token",
            title: "Ticker",
            render: (token) => lookupSymbol(token),
            bold: true,
          },
          {
            key: "action",
            title: "Action",
            render: (text) => {
              const colors: Dict<string> = { BUY: "aqua", SELL: "red" }
              return <span className={colors[text]}>{text}</span>
            },
            align: "center",
          },
          {
            key: "price",
            dataIndex: "data",
            children: [
              {
                key: "oraclePrice",
                title: "Oracle",
                render: (value) => `${format(value)} ${UST}`,
                align: "right",
              },
              {
                key: "price",
                title: "Price",
                render: (value) => `${format(value)} ${UST}`,
                align: "right",
              },
            ],
          },
          {
            key: "data.offerAmount",
            title: "Amount",
            render: (value, { token }) => formatAsset(value, token),
            align: "right",
          },
          {
            key: "data.returnAmount",
            title: "Return amount",
            render: (value) => formatAsset(value, UUSD),
            align: "right",
          },
        ]}
        dataSource={history}
      />

      {history.length >= OFFSET && (
        <Button
          onClick={() =>
            fetchMore({ variables: { offset: history.length, limit: OFFSET } })
          }
          disabled={loading}
          color="secondary"
          outline
          block
        >
          More
        </Button>
      )}
    </WithResult>
  )
}

export default OnChainSwapHistory
