import { useCallback, useEffect, useState } from "react"
import { Coin, LCDClient } from "@terra-money/terra.js"
import { sort } from "ramda"
import BigNumber from "bignumber.js"
import Table from "../../components/Table"
import Clickable from "../components/Clickable"
import StatusButton, { getStatusName } from "../components/StatusButton"
import { gt, gte, lt } from "../../libs/math"
import { format, formatAsset, lookup, lookupSymbol } from "../../libs/parse"
import { toAmount } from "../../libs/parse"
import { percent } from "../../libs/num"
import { useNetwork } from "../../hooks"
import { OptionType } from "../hooks/gqldocs"
import { useOptions } from "../hooks/useAdmin"
import { parsePercent } from "../utils"
import { UST } from "../../constants"

const DefaultOption: Omit<
  AdminOptionDetailSwap,
  "swappedAmount" | "offerDenom" | "askDenom"
> = {
  amount: "0",
  maxAmount: "0",
  maxSpread: "0",
  randomAmountRate: "0",
  randomMaxSpreadRate: "0",
  on: false,
}

const OnChainSwapOptions = () => {
  const { options = [], setOption } = useOptions<
    AdminOption<AdminOptionDetailSwap>,
    { setOnChainSwapOption: AdminOptionDetailSwap }
  >(OptionType.ONCHAIN_SWAP)

  /* simulate */
  const sortByOfferDenom = sort<AdminOption<AdminOptionDetailSwap>>(
    ({ option: a, option: b }) => a.offerDenom.localeCompare(b.offerDenom)
  )

  const simulated = useSimulate(options.map(({ option }) => option))
  const dataSource = sortByOfferDenom(options).map((option, index) => ({
    ...simulated[index],
    ...option,
    symbol: lookupSymbol(option.token),
  }))

  /* event */
  interface Params {
    token: string
    symbol: string
  }

  const initOption = ({ token, symbol }: Params) => {
    const answer = window.confirm(`Init ${symbol}`)
    answer && setOption({ variables: { ...DefaultOption, token } })
  }

  interface MutateParams extends Params {
    current: AdminOptionDetailSwap
  }

  interface MutateAmountParams extends MutateParams {
    key: "amount" | "maxAmount"
  }

  const mutateAmount = (params: MutateAmountParams) => {
    const { token, symbol, key, current } = params
    const input = prompt(`${symbol}:`, lookup(current[key], symbol)) ?? ""
    const invalid = !gt(input, 0)
      ? "Target must be a number greater than zero"
      : ""

    if (input) {
      invalid
        ? alert(invalid)
        : setOption({
            variables: {
              ...DefaultOption,
              ...current,
              token,
              [key]: toAmount(input),
            },
          })
    }
  }

  interface MutatePercentParams extends MutateParams {
    key: "maxSpread" | "randomAmountRate" | "randomMaxSpreadRate"
  }

  const mutatePercent = (params: MutatePercentParams) => {
    const { token, symbol, key, current } = params
    const input = prompt(`Target ${symbol}:`, percent(current[key])) ?? ""

    const parsed = parsePercent(input)
    const invalid = !input?.endsWith("%")
      ? "Target must end with '%'"
      : !(gt(parsed, 0) || lt(parsed, 0))
      ? "Target must be a non-zero number"
      : ""

    if (input) {
      invalid
        ? alert(invalid)
        : setOption({
            variables: { ...DefaultOption, ...current, token, [key]: parsed },
          })
    }
  }

  interface MutateStatusParams extends MutateParams {
    key: "on"
  }

  const mutateStatus = (params: MutateStatusParams) => {
    const { token, symbol, key, current } = params
    const next = !current[key]
    const answer = window.confirm(`${getStatusName(next)} ${symbol}?`)

    answer &&
      setOption({
        variables: { ...DefaultOption, ...current, token, [key]: next },
      })
  }

  return (
    <Table
      columns={[
        {
          key: "symbol",
          title: "Ticker",
          bold: true,
        },
        {
          key: "option.offerDenom",
          title: "offer",
          render: lookupSymbol,
        },
        {
          key: "option.askDenom",
          title: "ask",
          render: lookupSymbol,
        },
        {
          key: "oracle",
          title: "Oracle price",
          render: (value) => `${format(value)} ${UST}`,
          align: "right",
        },
        {
          key: "price",
          title: "Price",
          render: (value) => `${format(value)} ${UST}`,
          align: "right",
        },
        {
          key: "spread",
          title: "Spread",
          render: (value) => percent(value),
          align: "right",
        },
        {
          key: "option.swappedAmount",
          title: "Swapped amount",
          render: (value, { symbol }) =>
            gte(value, 0) ? formatAsset(value, symbol) : "-",
          align: "center",
        },
        {
          key: "option",
          title: "Editable options",
          children: [
            {
              key: "amount",
              title: "Amount",
              render: (value, { token, symbol, option: current }) => {
                const key = "amount"
                const handleClick = () =>
                  !current
                    ? initOption({ token, symbol })
                    : mutateAmount({ token, symbol, current, key })
                return (
                  <Clickable onClick={handleClick}>
                    {gte(value, 0) ? formatAsset(value, symbol) : "-"}
                  </Clickable>
                )
              },
              align: "center",
            },
            {
              key: "randomAmountRate",
              title: "Random Amount",
              render: (value, { token, symbol, option: current }) => {
                const key = "randomAmountRate"
                const handleClick = () =>
                  !current
                    ? initOption({ token, symbol })
                    : mutatePercent({ token, symbol, current, key })
                return (
                  <Clickable onClick={handleClick}>
                    {gte(value, 0) ? percent(value) : "-"}
                  </Clickable>
                )
              },
              align: "center",
            },
            {
              key: "maxSpread",
              title: "Max Spread",
              render: (value, { token, symbol, option: current }) => {
                const key = "maxSpread"
                const handleClick = () =>
                  !current
                    ? initOption({ token, symbol })
                    : mutatePercent({ token, symbol, current, key })
                return (
                  <Clickable onClick={handleClick}>
                    {gte(value, 0) ? percent(value) : "-"}
                  </Clickable>
                )
              },
              align: "center",
            },
            {
              key: "randomMaxSpreadRate",
              title: "Random Max Spread",
              render: (value, { token, symbol, option: current }) => {
                const key = "randomMaxSpreadRate"
                const handleClick = () =>
                  !current
                    ? initOption({ token, symbol })
                    : mutatePercent({ token, symbol, current, key })
                return (
                  <Clickable onClick={handleClick}>
                    {gte(value, 0) ? percent(value) : "-"}
                  </Clickable>
                )
              },
              align: "center",
            },
            {
              key: "maxAmount",
              title: "Max Amount",
              render: (value, { token, symbol, option: current }) => {
                const key = "maxAmount"
                const handleClick = () =>
                  !current
                    ? initOption({ token, symbol })
                    : mutateAmount({ token, symbol, current, key })
                return (
                  <Clickable onClick={handleClick}>
                    {gte(value, 0) ? formatAsset(value, symbol) : "-"}
                  </Clickable>
                )
              },
              align: "center",
            },
            {
              key: "on",
              title: "On/Off",
              render: (on, { token, symbol, option: current }) => {
                const key = "on"
                const handleClick = () =>
                  !current
                    ? initOption({ token, symbol })
                    : mutateStatus({ token, symbol, current, key })
                return <StatusButton on={on} onClick={handleClick} />
              },
              align: "center",
            },
          ],
        },
      ]}
      dataSource={dataSource}
    />
  )
}

export default OnChainSwapOptions

/* simulate */
const useSimulate = (options: AdminOptionDetailSwap[]) => {
  const { lcd: URL, chainID } = useNetwork()

  type Result = { price: string; oracle?: string; spread?: string }
  const [data, setData] = useState<Result[]>([])

  const simulate = useCallback(
    async ({ amount, offerDenom, askDenom }: AdminOptionDetailSwap) => {
      const lcd = new LCDClient({ URL, chainID })

      const exchangeRate = await lcd.oracle.exchangeRate("uusd")
      const oracle = exchangeRate?.amount.toString()

      const offerCoin = new Coin(offerDenom, amount)
      const simulation = await lcd.market.swapRate(offerCoin, askDenom)
      const returnAmount = simulation?.toData().amount
      const ratio = new BigNumber(returnAmount).div(amount)
      const priceLuna = (
        offerDenom === "uluna" ? ratio : new BigNumber(1).div(ratio)
      ).toString()

      const spread =
        priceLuna &&
        oracle &&
        new BigNumber(1).minus(new BigNumber(priceLuna).div(oracle)).toString()

      return { price: priceLuna, oracle, spread }
    },
    [URL, chainID]
  )

  const simulateAll = useCallback(
    async (options: AdminOptionDetailSwap[]) => {
      const queries = options.map(simulate)
      const data = await Promise.all(queries)
      setData(data)
    },
    [simulate]
  )

  useEffect(() => {
    simulateAll(options)

    // eslint-disable-next-line
  }, [JSON.stringify(options), simulateAll])

  return data
}
