import { RouteProps } from "react-router-dom"

import BuyToken from "./pages/BuyToken"
import SellToken from "./pages/SellToken"
import TerraswapMM from "./pages/TerraswapMM"
import UniswapMM from "./pages/UniswapMM"
import OnChainSwap from "./pages/OnChainSwap"
import Liquidate from "./pages/Liquidate"
import SendToken from "./pages/SendToken"
import Mixing from "./pages/Mixing"

export enum MenuKey {
  TERRASWAPMM = "TerraswapMM",
  UNISWAPMM = "UniswapMM",
  BUYTOKEN = "BuyToken",
  SELLTOKEN = "SellToken",
  ONCHAINSWAP = "OnChainSwap",
  LIQUIDATE = "Liquidate",
  SENDTOKEN = "SendToken",
  MIXING = "Mixing",
}

export const omit: MenuKey[] = []
export const menu: Record<MenuKey, RouteProps> = {
  [MenuKey.TERRASWAPMM]: { path: "/TerraswapMM", component: TerraswapMM },
  [MenuKey.UNISWAPMM]: { path: "/UniswapMM", component: UniswapMM },
  [MenuKey.BUYTOKEN]: { path: "/BuyToken", component: BuyToken },
  [MenuKey.SELLTOKEN]: { path: "/SellToken", component: SellToken },
  [MenuKey.ONCHAINSWAP]: { path: "/OnChainSwap", component: OnChainSwap },
  [MenuKey.LIQUIDATE]: { path: "/Liquidate", component: Liquidate },
  [MenuKey.SENDTOKEN]: { path: "/SendToken", component: SendToken },
  [MenuKey.MIXING]: { path: "/Mixing", component: Mixing },
}

export const getPath = (key: MenuKey) => menu[key].path as string
