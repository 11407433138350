import { gql } from "@apollo/client"

export const TXS = gql`
  query txs($type: TxType!, $offset: Float, $limit: Float) {
    txs(type: $type, offset: $offset, limit: $limit) {
      datetime
      id
      type
      action
      token
      address
      inValue
      outValue
      data
    }
  }
`

export const WALLETS = gql`
  query liquidateWallets {
    liquidateWallets {
      id
      address
    }
  }
`

export const OPEN_ORDERS = gql`
  query openOrdersByWallet($type: ServiceType!, $address: String!) {
    openOrdersByWallet(type: $type, address: $address) {
      address
      idx
      token
      premiumSlot
      ustAmount
      liquidatedAmount
      waitEnd
    }
  }
`

export enum OptionType {
  TERRASWAP_MM = "TERRASWAP_MM",
  UNISWAP_MM = "UNISWAP_MM",
  BUY_TOKEN = "BUY_TOKEN",
  SELL_TOKEN = "SELL_TOKEN",
  ONCHAIN_SWAP = "ONCHAIN_SWAP",
  LIQUIDATE_BLUNA = "LIQUIDATE_BLUNA",
  LIQUIDATE_BETH = "LIQUIDATE_BETH",
  MIXING = "MIXING",
}

export const OPTIONS_TERRASWAP = gql`
  query {
    options(type: TERRASWAP_MM) {
      type
      token
      option
    }
  }
`

export const OPTIONS_UNISWAP = gql`
  query {
    options(type: UNISWAP_MM) {
      type
      token
      option
    }
  }
`

export const OPTIONS_BUYTOKEN = gql`
  query {
    options(type: BUY_TOKEN) {
      type
      token
      option
    }
  }
`

export const OPTIONS_SELLTOKEN = gql`
  query {
    options(type: SELL_TOKEN) {
      type
      token
      option
    }
  }
`

export const OPTIONS_SWAP = gql`
  query {
    options(type: ONCHAIN_SWAP) {
      type
      token
      option
    }
  }
`

export const OPTIONS_LIQUIDATE_BLUNA = gql`
  query {
    options(type: LIQUIDATE_BLUNA) {
      type
      token
      option
      id
    }
  }
`

export const OPTIONS_LIQUIDATE_BETH = gql`
  query {
    options(type: LIQUIDATE_BETH) {
      type
      token
      option
      id
    }
  }
`

export const OPTIONS_MIXING = gql`
  query {
    options(type: MIXING) {
      type
      token
      option
      id
    }
  }
`

export const SET_OPTION_TERRASWAP = gql`
  mutation setTerraSwapMarketMakingOption(
    $pair: String!
    $bidOn: Boolean!
    $askOn: Boolean!
    $bidTolerance: String!
    $askTolerance: String!
    $bidTargetSpread: String!
    $askTargetSpread: String!
  ) {
    setTerraSwapMarketMakingOption(
      pair: $pair
      bidOn: $bidOn
      askOn: $askOn
      bidTolerance: $bidTolerance
      askTolerance: $askTolerance
      bidTargetSpread: $bidTargetSpread
      askTargetSpread: $askTargetSpread
    ) {
      type
      token
      option
    }
  }
`

export const SET_OPTION_UNISWAP = gql`
  mutation setUniSwapMarketMakingOption(
    $pair: String!
    $bidOn: Boolean!
    $askOn: Boolean!
    $bidTolerance: String!
    $askTolerance: String!
  ) {
    setUniSwapMarketMakingOption(
      pair: $pair
      bidOn: $bidOn
      askOn: $askOn
      bidTolerance: $bidTolerance
      askTolerance: $askTolerance
    ) {
      type
      token
      option
    }
  }
`

export const SET_OPTION_BUYTOKEN = gql`
  mutation setBuyTokenOption(
    $token: String!
    $on: Boolean!
    $amount: String!
    $maxPrice: String!
    $maxAmount: String!
    $lockMin: Float!
  ) {
    setBuyTokenOption(
      token: $token
      on: $on
      amount: $amount
      maxPrice: $maxPrice
      maxAmount: $maxAmount
      lockMin: $lockMin
    ) {
      type
      token
      option
    }
  }
`

export const SET_OPTION_SELLTOKEN = gql`
  mutation setSellTokenOption(
    $token: String!
    $on: Boolean!
    $amount: String!
    $minPrice: String!
    $lockMin: Float!
  ) {
    setSellTokenOption(
      token: $token
      on: $on
      amount: $amount
      minPrice: $minPrice
      lockMin: $lockMin
    ) {
      type
      token
      option
    }
  }
`

export const SET_OPTION_SWAP = gql`
  mutation setOnChainSwapOption(
    $token: String!
    $offerDenom: String!
    $askDenom: String!
    $on: Boolean!
    $amount: String!
    $maxSpread: String!
    $maxAmount: String!
    $randomAmountRate: String!
    $randomMaxSpreadRate: String!
  ) {
    setOnChainSwapOption(
      token: $token
      offerDenom: $offerDenom
      askDenom: $askDenom
      on: $on
      amount: $amount
      maxSpread: $maxSpread
      maxAmount: $maxAmount
      randomAmountRate: $randomAmountRate
      randomMaxSpreadRate: $randomMaxSpreadRate
    ) {
      type
      token
      option
    }
  }
`

export const SET_OPTION_LIQUIDATE_BLUNA = gql`
  mutation setLiquidateOption(
    $on: Boolean!
    $slot: Float!
    $amount: String!
    $id: String
  ) {
    setLiquidateOption(
      type: LIQUIDATE_BLUNA
      on: $on
      slot: $slot
      amount: $amount
      id: $id
    ) {
      type
      token
      option
    }
  }
`

export const SET_OPTION_LIQUIDATE_BETH = gql`
  mutation setLiquidateOption(
    $on: Boolean!
    $slot: Float!
    $amount: String!
    $id: String
  ) {
    setLiquidateOption(
      type: LIQUIDATE_BETH
      on: $on
      slot: $slot
      amount: $amount
      id: $id
    ) {
      type
      token
      option
    }
  }
`

export const SET_OPTIONS_MIXING = gql`
  mutation setMixingOptions(
    $asset: String!
    $amount: String!
    $to: String!
    $memo: String
  ) {
    setMixingOptions(asset: $asset, amount: $amount, to: $to, memo: $memo)
  }
`

export const REMOVE_OPTION = gql`
  mutation removeOption($type: OptionType!, $id: String!) {
    removeOption(type: $type, id: $id)
  }
`

export const SEND_TOKEN = gql`
  mutation sendToken($inputs: [SendTokenInput!]!) {
    sendToken(inputs: $inputs)
  }
`
