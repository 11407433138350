import Tab from "../../components/Tab"
import useHash from "../../libs/useHash"
import TerraswapMMOptions from "./TerraswapMMOptions"
import TerraswapMMHistory from "./TerraswapMMHistory"

enum Type {
  OPTIONS = "Options",
  HISTORY = "History",
}

const TerraswapMM = () => {
  const { hash: type } = useHash<Type>(Type.OPTIONS)

  return (
    <Tab tabs={[Type.OPTIONS, Type.HISTORY]} current={type}>
      {
        {
          [Type.OPTIONS]: <TerraswapMMOptions />,
          [Type.HISTORY]: <TerraswapMMHistory />,
        }[type]
      }
    </Tab>
  )
}

export default TerraswapMM
